<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Accounts List</h4>

    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Extended tables</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            class="select-default"
            v-model="pagination.perPage"
            placeholder="Per page">
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm"
                      placeholder="Search"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped"
                    :data="queriedData"
                    border
                    style="width: 100%">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :prop="column.prop"
                             :label="column.label">
            </el-table-column>
            <!--
              <el-table-column label="Image">
                <template slot-scope="scope">
                  <img src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Fbackground&psig=AOvVaw1KtdDIIAibNTHRdg8KfqNF&ust=1709646065216000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCJiYu4be2oQDFQAAAAAdAAAAABAI" alt="Image" style="width: 50px; height: 50px;">
                </template>
              </el-table-column>
            -->
            <el-table-column
              :min-width="120"
              fixed="right"
              class-name="td-actions"
              label="Actions">
              <template slot-scope="props">
                <p-button type="info" size="sm" icon @click="handleLike(props.$index, props.row)">
                  <i class="fa fa-solid fa-eye"></i>
                </p-button>
                <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  
  //import users from './users'
  import { mapState } from "vuex";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    components: {
      PPagination,
      
    },
    computed: {
      
      
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['name', 'email', 'age'],
        tableColumns: [
          {
            prop: 'user_name',
            label: 'Name',
            minWidth: 100
          },
          {
            prop: 'user_email',
            label: 'Email',
            minWidth: 120
          },
          {
            prop: 'user_authentication_method',
            label: 'Signup Type',
            minWidth: 60,
          },
          {
            prop: 'user_mobile',
            label: 'Contact Number',
            minWidth: 80
          },
          {
            prop: 'user_status',
            label: 'status',
            minWidth: 60
          },
          {
            prop: 'user_country',
            label: 'Country',
            minWidth: 60
          },
          {
            prop: 'created_at',
            label: 'Created',
            minWidth: 60
          },
          
          
        ],
        tableData: []
      }
    },
    methods: {
      handleLike (index, row) {
        //alert(`Your want to like ${row.name}`)
        this.$router.push(`singleuser?user_id=${row.user_id}`)
      },
      handleEdit (index, row) {
        alert(`Your want to edit ${row.user_id}`)
      },
      handleDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      getUsers(){
        this.$http.get('dashboard/list_user')
        .then(response => {
        let res = response.body
        console.log(res.data.data)
        this.tableData = res.data.data
        for (let user of this.tableData) {
            if (user.user_authentication_method === 1) {
              user.user_authentication_method = 'Google';
              if (user.user_status === 1) {
              user.user_status = 'Active';
            }
            }
            
          }
        })
      }

    },
    beforeMount(){
      this.getUsers()
    }
    
  }
</script>
<style lang="scss">

  .el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
  }
  
</style>
