<template>
  <div>
    <div class="row">
    
    <div class="col-lg-4 col-md-5">

      <user-card :user-details="user" :coupon-details="coupon">
      </user-card>

    </div>
    
    <div class="col-lg-8 col-md-5">
      <h3>List of Profiles</h3>
      <h3 class="text-center text-danger" v-if="profiles.length == 0">No profiles created yet!</h3>
        <!-- Display profiles -->
        <div class="row">
          <!-- Repeat this card for each profile -->
          <div v-for="profile in profiles" :key="profile.id" class="col-md-4">
            <div class="card profile-card">
              <div class="d-flex photo_number">
                <img v-if="!profile.profile_photo" src="../../../../assets/images/blank-profile-picture.webp" class="p-1 card-img-top img-fluid profile-pics rounded-circle" alt="">
                <img v-else :src="profile.profile_photo" class="p-1 card-img-top img-fluid profile-pics rounded-circle" alt="">
                <small class="p-2">+{{ user.country_code }}- 0000000</small>

              </div>
                <h6 class="card-title mx-auto" v-if="!profile.profile_name">Nil</h6>
                <h6 class="card-title mx-auto" v-else>{{ profile.profile_name }}</h6>
            </div>
          </div>
        </div>
      </div> 
  </div>
  <SlideYUpTransition>
    
  </SlideYUpTransition>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">List of Coupons</h4>

    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Extended tables</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            class="select-default"
            v-model="pagination.perPage"
            placeholder="Per page">
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm"
                      placeholder="Search"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped"
                    :data="queriedData"
                    border
                    style="width: 100%">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :prop="column.prop"
                             :label="column.label">
            </el-table-column>
            
            
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import EditProfileForm from './UserProfile/EditProfileForm.vue'
  import UserCard from './UserProfile/UserCard.vue'
  import moment from 'moment'
  import modal from '../../../UIComponents/Modal.vue'
  import { formatDate } from '@fullcalendar/vue'

  Vue.filter('formatDate', function(value){
    if(value){
      return moment(String(value)).format('DD/MM/YYYY')
    }
  })
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default {
    
    
    components: {
      PPagination,
      EditProfileForm,
      UserCard,
      modal,
      
    },
    computed: {
      
      
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        user:[],
        coupon:[],
        profiles:[],
        
        //coupon,
        //profile,
        
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['name', 'email', 'age'],
        tableColumns: [
          {
            prop: 'coupon_code',
            label: 'Coupon code',
            minWidth: 70
          },
          {
            prop: 'coupon_value',
            label: 'Coupon Value',
            minWidth: 70
          },
          {
            prop: 'coupon_balance',
            label: 'Balance',
            minWidth: 70
          },
          {
            prop: 'coupon_status',
            label: 'Status',
            minWidth: 70
          },
          {
            prop: 'package_name',
            label: 'Package',
            minWidth: 70
          },
          {
            prop: 'created_at',
            label: 'Created',
            minWidth: 70
          },
          {
            prop: 'updated_at',
            label: 'Updated',
            minWidth: 70
          },
          
          
        ],
        
      }
    },
    methods: {
      handleLike (index, row) {
        alert(`Your want to like ${row.coupon_id}`)
        
      },
      handleEdit (index, row) {
        alert(`Your want to edit ${row.coupon_id}`)
      },
      handleDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      getUserInfo(){
        const user_id = this.$route.query.user_id
        console.log(this.$route.query.user_id)
        this.$http.get(`dashboard/adm_get_user_details?user_id=${user_id}`)
        .then(response => {
        this.user = response.body.data.user_info
        this.coupon = response.body.data.coupons
        this.tableData = this.coupon
        this.profiles = response.body.data.profiles
        
        //this.rf = response.body.data.user_info.user_name
        //console.log(this.rf)
        //this.tableData = response.body.data.coupons
        //console.log(tableData)
        /*
        for (coupon in this.coupon){
          if(coupon.coupon_balance != 0){
            this.coupon = coupon
          }
        }
         */
        })
      },
      

    },
    beforeMount(){
      this.getUserInfo()
      
    }
    
  }
</script>
<style lang="scss">

  .el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
  }
  .profile-card{
    height: 80px;
    background-color: white;
    border-radius: 10px;
  }
  .profile-pics{
    width:50px;
    height: 50px;
  }
  .photo_number{
    justify-content: space-between;
  }
</style>

